<template>
  <div style="display:flex; margin: 15px 0 0 0">
    <div style="display:flex;" v-if="list.length > 0">
      <!-- 左侧大图 -->
      <div style="width: 420px;">
        <card :card="list[0]" width="415px" height="315px" fit="cover" />
      </div>
      <!-- 右侧6图 -->
      <div style="display:flex; flex-wrap: wrap;" v-if="list.length > 1">
        <div v-for="(card, index) in list.slice(1)" :key="index" style="margin-left: 13px;">
          <card :card="card" width="210px" height="118px" fit="cover" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonQuery } from '@/api/commonquery'
import Card from '../components/Card.vue'

export default {
  name: 'VideoSection',
  components: {
    Card,
  },
  props: {
    topType: {
      type: Object,
      default: () => {},
    },
    limit: {
      type: Number,
      default: 7,
    },
  },
  data() {
    return {
      list: [],
    }
  },
  watch: {
    topType() {
      this.getData()
    },
  },
  created() {
    if (this.topType) {
      this.getData()
    }
  },
  methods: {
    async getData() {
      let query = {
        model: 'video',
        query: {
          topType: {
            $eq: this.topType._id,
          },
        },
        limit: this.limit,
      }
      let res = await CommonQuery(query)
      this.list = res.data.map((video) => {
        return {
          ...video,
          readed: video.readed || 0,
          to: {
            name: 'videoDetail',
            query: {
              id: video._id,
            },
          },
        }
      })
    },
  },
}
</script>

<style scoped></style>
