<template>
  <main id="tg-main" class="tg-main tg-haslayout">
    <div class="tg-sectionspace tg-haslayout">
      <div class="container">
        <section-space
          v-for="topType of topTypes"
          :key="topType._id"
          :section-name="topType.name"
          :model="topType"
          :subModelKey="'secondType'"
          :top-model-name="'video'"
        >
          <video-section :limit="7" :topType="topType" />
        </section-space>
      </div>
    </div>
  </main>
</template>

<script>
import { Pagination } from 'element-ui'
import { CommonQuery, ListQuery } from '../api/commonquery'
import SectionSpace from '../components/SectionSpace.vue'
import VideoSection from '../components/VideoSection.vue'

export default {
  components: {
    VideoSection,
    SectionSpace,
    [Pagination.name]: Pagination,
  },
  data() {
    return {
      topTypes: [],
    }
  },
  methods: {
    async getTopType() {
      let data = {
        model: 'videoTopType',
      }
      let res = await ListQuery(data)
      this.topTypes = res.data
    },

    async getData(newQuery) {
      this.initbasicData(newQuery)
      let data = {
        model: 'video',
        query: {},
        skip: (this.basicdata.page - 1) * this.basicdata.pagesize,
        limit: this.basicdata.pagesize,
      }
      // 分类筛选
      if (newQuery.type) {
        Object.assign(data.query, {
          type: {
            $eq: newQuery.type,
          },
        })
      }
      // 搜索关键字
      if (newQuery.searchkey) {
        Object.assign(data, {
          searchKey: newQuery.searchkey,
        })
      }

      // 排序
      if (newQuery.sort) {
        let order = 'asc'
        let key = newQuery.sort
        if (newQuery.sort.startsWith('-')) {
          order = 'desc'
          key = newQuery.sort.substring(1)
        }
        Object.assign(data, {
          sort: { key, order },
        })
      }
      let res = await CommonQuery(data)
      this.list = res.data.map((video) => {
        return {
          ...video,
          readed: video.readed || 0,
          to: {
            name: 'videoDetail',
            query: {
              id: video._id,
            },
          },
        }
      })
      this.basicdata.total = res.total
    },
    onTypeChange(item) {
      let query = { ...this.$route.query }
      Object.assign(query, {
        type: item._id,
      })
      this.changeSelectUrl(query)
    },
  },
  created() {
    this.getTopType()
  },
}
</script>
